import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/maniac-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1980 slasher movie, Maniac"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 04</h1>
                    <h2>Maniac</h2>
                    <h3>April 30, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <h1>Transcript</h1>
                        <p><Link to="/episodes/maniac">Back to the episode details</Link></p>
                        <div>
                            <p>Bryan White (00:04.665)
                            All right, you&#39;re listening to the Brothers Grimm podcast presented by the Cinema Suicide Film Society on Substack. If you wanna keep up with us between episodes, you can also find us on Twitter at GrimBrosPod. And if you like what you hear, you can subscribe to us wherever you get your podcasts. Also, you&#39;d be doing us a favor by leaving us a five-star review on Apple Podcasts. Just wanna get that all out of the way right up top. I&#39;m Brian White, editor in grief of Cinema Suicide. I&#39;m joined by my co-host and actual brother, Dave White. Dave, how are you?</p>
                            <p>Queen Of The Lizard People (00:33.49)
                            Oh, hey, pretty good, pretty good. I got a question for you though. I was thinking about this earlier.</p>
                            <p>Bryan White (00:34.969)
                            Hey.</p>
                            <p>Bryan White (00:38.586)
                            Shoot.</p>
                            <p>Queen Of The Lizard People (00:40.31)
                            What do you think is the creepiest scene from any horror movie you&#39;ve ever seen?</p>
                            <p>Bryan White (00:45.388)
                            Oh, that&#39;s a-</p>
                            <p>Queen Of The Lizard People (00:47.77)
                            Yeah, you didn&#39;t see me coming with that, did you?</p>
                            <p>Bryan White (00:49.625)
                            No, that&#39;s a real surprise. I usually go to I usually go to the Sentinel when I think about creepy. The Beverly D&#39;Angelo masturbating scene is really, really upsetting. Also, when the sort of the all the sort of spooks come out of hell and are just kind of like wandering around the apartment building and chasing Christina Reigns like that, that really gets to me.</p>
                            <p>Queen Of The Lizard People (01:12.916)
                            Yes.</p>
                            <p>Bryan White (01:19.325)
                            I don&#39;t know if I had more time to prepare. I could probably think of something like that.</p>
                            <p>Queen Of The Lizard People (01:20.81)
                            Yeah, see, I was gonna give you more time to prepare, and I thought, no, I&#39;m just gonna come in hot with it.</p>
                            <p>Bryan White (01:25.289)
                            I don&#39;t know, what do you got?</p>
                            <p>Queen Of The Lizard People (01:28.41)
                            I used to think that it was the scene in The Haunting where everything&#39;s kind of going to hell and the ghost is banging on the walls and she wakes up and she thinks it&#39;s Theo holding her hand but then like the lights come on and Theo&#39;s like on the other side of the room. That seems pretty creepy but then I was rewatching Black Christmas like a month ago and I&#39;m pretty sure it&#39;s that scene where Claire is looking into the closet and she doesn&#39;t really know</p>
                            <p>Bryan White (01:31.191)
                            Thanks for watching!</p>
                            <p>Bryan White (01:39.265)
                            Thanks for watching!</p>
                            <p>Bryan White (01:42.865)
                            Come on.</p>
                            <p>Bryan White (01:54.028)
                            Where? And she sees the eye.</p>
                            <p>Queen Of The Lizard People (01:58.25)
                            That&#39;s later. This is the beginning, the first one, and she&#39;s like looking at the closet and she&#39;s like, who is that? Who&#39;s in there? We can&#39;t see anything. And we&#39;re like, why are you going into the closet? Get away from the closet! I think it&#39;s that, I think it&#39;s that scene.</p>
                            <p>Bryan White (01:59.168)
                            Oh, okay.</p>
                            <p>Bryan White (02:14.065)
                            Yeah. Got it. I&#39;m gonna be thinking about that for the rest of the show now. But yeah, yeah. So Oh</p>
                            <p>Queen Of The Lizard People (02:18.43)
                            Yeah, both of those movies better than this movie.</p>
                            <p>Bryan White (02:26.245)
                            In the past, like the last few shows that we&#39;ve done, we&#39;ve gone to a well of like real arch and camp, and they&#39;ve been a lot of fun to talk about, but this is a total shift in tone for us so far, and it was a little jarring. I think I could confidently call this like a ruffy in the classic sense. It&#39;s a pretty brutal movie. So, not really. No, there&#39;s not a whole lot of fun to be had here.</p>
                            <p>Queen Of The Lizard People (02:48.452)
                            It is neither campy nor fun.</p>
                            <p>Bryan White (02:56.125)
                            know what we&#39;re talking about. Let me let me give you a taste.</p>
                            <p>Bryan White (03:33.687)
                            you</p>
                            <p>Queen Of The Lizard People (04:26.075)
                            you</p>
                            <p>Bryan White (04:32.705)
                            So that&#39;s it, maniac.</p>
                            <p>Queen Of The Lizard People (04:33.65)
                            I tell you what, if that movie were a minute and 40 seconds long, it would have been amazing. That&#39;s all the best parts of the movie right there.</p>
                            <p>Bryan White (04:43.825)
                            Yeah, yeah. So well, there&#39;s a there&#39;s a reason for that. But we&#39;ll we&#39;ll get into it. So this is 1980 Maniac. So some facts about the show. This was released in 1980. Some other movies released that year, Friday the 13th. The Fog. Saturn three. Anthropophagus. Yeah, Motel Hell Nightmare City, which is great.</p>
                            <p>Queen Of The Lizard People (05:02.75)
                            So yeah.</p>
                            <p>Queen Of The Lizard People (05:08.162)
                            Oh, yikes.</p>
                            <p>Queen Of The Lizard People (05:13.27)
                            It&#39;s up there with anthropophagous.</p>
                            <p>Bryan White (05:16.827)
                            And the notorious cannibal holocaust. So this was directed by William Lustig, who has gone on to do much better movies than this. I would actually say that this was probably like a like a living classroom for him, because the next movie he made, Vigilante. I&#39;m not a humongous fan of it, but it&#39;s a remarkably better movie than Maniac is. So before he was doing this,</p>
                            <p>Bryan White (05:43.765)
                            He&#39;s one of these like New York guys. He was working around the same time like Larry Cohen was and Frank Hatt. He made Maniac Cop Uncle Sam.</p>
                            <p>Queen Of The Lizard People (05:48.95)
                            I mean, you made Maniac Cop. You freaked out Maniac Cop.</p>
                            <p>Queen Of The Lizard People (05:53.91)
                            Which I watched last night and that movie is a fucking pile of garbage. It is confusing and really like right-wing.</p>
                            <p>Bryan White (05:57.885)
                            It&#39;s really, it&#39;s really bad. I think it&#39;s the it&#39;s trying to see who&#39;s trying to make trauma without the trauma. It&#39;s super, super silly. But before before he made before he made this, he he was working in New York producing, directing porn. He did as far as. Yeah, yeah, yeah. Like a lot of these guys, it seems like it&#39;s a pretty</p>
                            <p>Queen Of The Lizard People (06:21.531)
                            which is very common.</p>
                            <p>Bryan White (06:27.805)
                            and you sort of can learn the ropes.</p>
                            <p>Queen Of The Lizard People (06:29.691)
                            They made more money making porn than they did making these movies.</p>
                            <p>Bryan White (06:35.385)
                            $30,000 of the $350,000 budget came from directly from Lustig&#39;s own like earnings from from directing porno. But like if it&#39;s it&#39;s you know, it ain&#39;t the greatest work in the world, but like it&#39;s a it&#39;s definitely a way to sort of get in and learn how to make a movie because like, they&#39;ll let anybody make one of those as long as</p>
                            <p>Queen Of The Lizard People (06:53.75)
                            Yeah, Wes Craven also made porn.</p>
                            <p>Queen Of The Lizard People (07:05.37)
                            Nice. That&#39;s gotta be the best part about making porn, is you get to come up with fun names.</p>
                            <p>Bryan White (07:06.076)
                            Yeah, but the road</p>
                            <p>Bryan White (07:09.925)
                            You get a funny name. But yeah, so the road from porno to to maniac was basically when Dario Argento came to America to shoot the sort of location stuff for Inferno. Lustig was the was a production manager for the location shots. So he he made inroads. He met Argento and they you know, they they work together and they it sounds like he kind of showed him around and and was also his guide. So they had a bit of a repartee.</p>
                            <p>Bryan White (07:39.885)
                            they it sounds like they had not like a written agreement but they had he had sort of talked him into producing maniac because maniac had been like written for a couple of years before this and they&#39;ve been trying to get the money to do it and he. Yeah, we&#39;ll say we&#39;ll say loosely it was probably written on a napkin.</p>
                            <p>Queen Of The Lizard People (07:53.091)
                            Let&#39;s use the term written really loosely here.</p>
                            <p>Queen Of The Lizard People (07:58.45)
                            Because I&#39;m still not convinced this has a script.</p>
                            <p>Bryan White (08:02.285)
                            Yeah, yeah, like, based on like how this movie shakes out, it&#39;s really kind of unclear how much of it was actually written because it&#39;s it&#39;s very clear that Carolyn Monroe is in this movie just because her husband kicked in basically bought her a role for like a couple hundred grand. But originally, her role was set aside for Daria Niccolotti. And</p>
                            <p>Queen Of The Lizard People (08:26.33)
                            She has a very Dario Nickelodee look to her. Like she looks a lot like her, and if nothing else, she&#39;s got that European look. That all of his Argento women have, or like the Hammer women have. Like it&#39;s that blonde, kind of serious, but a little bit empty.</p>
                            <p>Bryan White (08:31.13)
                            You&#39;re right.</p>
                            <p>Bryan White (08:36.886)
                            yet.</p>
                            <p>Bryan White (08:50.925)
                            She&#39;s a little she&#39;s a little vapid, but also she&#39;s a fashion photographer, which is like if you&#39;re making a jello, you have to have a fashion photography scene. But yeah, so this was this is all set up. I think Goblin was, I think, the original pick for who was going to do the soundtrack. But this is like a weird period for Goblin where the band is kind of coming apart. And it&#39;s this like revolving door sort of.</p>
                            <p>Queen Of The Lizard People (08:52.516)
                            Yeah.</p>
                            <p>Queen Of The Lizard People (08:59.394)
                            Yep.</p>
                            <p>Queen Of The Lizard People (09:15.69)
                            Yeah, they weren&#39;t really Goblin at that point. They were just Claudia Simonetti.</p>
                            <p>Bryan White (09:20.325)
                            was it was Claudio Siminetti. I think Pignatelli was also like the only other guy who was still sort of like of the classic lineup. But but anyways, if you know, lusting now, it&#39;s because he owns Blue Underground. And if you&#39;re listening to the show, you probably have some of their releases. This movie stars Joe Spinnell, from basically everything that was produced around New York City between 1975 and 1985. He&#39;s character actor he was</p>
                            <p>Queen Of The Lizard People (09:24.undefined)
                            Yep.</p>
                            <p>Queen Of The Lizard People (09:46.85)
                            If you need a guy to sweat on camera, he&#39;s your dude.</p>
                            <p>Bryan White (09:51.885)
                            Yeah, he and he looks he&#39;s like very he&#39;s like I think second only to maybe like shit taxi. It&#39;s always sunny. Danny. Danny DeVito. He&#39;s like second to Danny DeVito for like New York guy. He&#39;s in taxi driver. He&#39;s in the Godfather. He&#39;s in. Fuck.</p>
                            <p>Queen Of The Lizard People (10:03.511)
                            Danny DeVito.</p>
                            <p>Bryan White (10:22.045)
                            notable cast member, we mentioned her already, Caroline Monroe, who is well known from the Hammer movies, Dracula 80, 1972. She was a model, she was a Bond girl, she was in The Spy Who Loved Me, and this is the second time that these two have worked together, having previously been in the notoriously terrible Star Wars ripoff Star Crash, and it wouldn&#39;t be the last time that they worked together, and they would also show up again in The Last Horror Movie, which I haven&#39;t seen.</p>
                            <p>Bryan White (10:51.925)
                            and produced by Joe Spinell. So, yeah, yeah, this this movie is this movie is something I used to have really. I used to I used to hold it in really high regard when when I first got it, I really, really liked it. And then when I watched it for this and I was taking notes and really sort of taking it in and kind of familiarizing with the story beats. So I talk about it. I came out the other side with a much milder opinion of it. Like, I still like.</p>
                            <p>Bryan White (11:22.226)
                            I think it&#39;s fair to say that you did not care for this.</p>
                            <p>Queen Of The Lizard People (11:25.231)
                            No, no. What I will tell you is, here&#39;s how I described it. It&#39;s like taxi driver if taxi driver was made by people with less talent and less money.</p>
                            <p>Bryan White (11:35.446)
                            Hahaha!</p>
                            <p>Queen Of The Lizard People (11:36.67)
                            but I also think it would be great if it was remade with puppets.</p>
                            <p>Bryan White (11:42.866)
                            Yeah, yeah, so they I mean they remade it like like 10 years ago with with with with Elijah would as the Frank Zito character. Have you seen it. Have you seen it because I have I reviewed it for I reviewed it for cinema suicide when it was on YouTube. I really like that one I really did not care for because mostly because</p>
                            <p>Queen Of The Lizard People (11:46.591)
                            Oh, that&#39;s right. Go.</p>
                            <p>Queen Of The Lizard People (11:51.91)
                            Just scraping the bottom of the barrel. No.</p>
                            <p>Queen Of The Lizard People (12:04.01)
                            I don&#39;t really hold these movies in high regard, only because I associate them with a certain kind of horror, bro. That it&#39;s just sort of like, you know, I don&#39;t care if you wanna watch a movie where women are getting brutally murdered, like where that&#39;s the whole fucking movie. And I know I chose this one, so it&#39;s kinda on me, but I associate them with that kind of like real grimy, like, hey, have you seen Driller Killer? Like, yeah, and I don&#39;t need to see it again. Like, I don&#39;t need to see Maniac</p>
                            <p>Bryan White (12:12.497)
                            Yeah.</p>
                            <p>Bryan White (12:32.328)
                            Yeah.</p>
                            <p>Queen Of The Lizard People (12:33.95)
                            remade because there isn&#39;t much there to remake in the first place.</p>
                            <p>Bryan White (12:37.665)
                            Yeah, like, and from what I remember, like, it&#39;s, there&#39;s definitely like that was a little bit more conventionally movie, like, where where we go through this, we&#39;re going to find out that there&#39;s not a whole lot of movie going on in this movie. The 2012 remake, I hope that&#39;s the right year I think I think that&#39;s right, but like that one also the reason that I really didn&#39;t like that one is because it has this very obnoxious gimmick, where you see the movie in first person.</p>
                            <p>Bryan White (13:06.665)
                            only see Elijah Wood like in reflections and stuff. So you&#39;re seeing it through his eyes. And they even do this thing where like it the screen like blacks out for a second every now and then like he&#39;s blinking. It&#39;s a pain in the ass.</p>
                            <p>Queen Of The Lizard People (13:18.45)
                            I&#39;m not gonna lie, that sounds fucking cheesy.</p>
                            <p>Bryan White (13:23.126)
                            Gaspar Noe did it in it in it. He did it and enter the void and that&#39;s actually appropriate and it really works but in this I get what they were going for but I guess you have to like really be into the movie in the first place because like I don&#39;t know it just it wasn&#39;t very good.</p>
                            <p>Queen Of The Lizard People (13:24.67)
                            Okay, well, that explains why it&#39;s fucking cheesy.</p>
                            <p>Queen Of The Lizard People (13:41.351)
                            Well, I&#39;ll tell you what, here&#39;s, you want to remake it? You remake it with puppets, or better yet, you remake it and Frank and Anna open a detective agency, or Frank and Anna open a diner in the Bowery. Any of these are better options.</p>
                            <p>Bryan White (13:50.47)
                            Ha ha ha.</p>
                            <p>Bryan White (13:55.925)
                            Yeah, yeah. So after this movie was made, there was a there was a promotional short that was made for a sequel that they planned on making. Lustig basically washed his hands of this. He was done with it. So it fell to This was 86. So I don&#39;t know what he was doing around that time.</p>
                            <p>Queen Of The Lizard People (14:10.33)
                            But do you think he was like, I am famous now?</p>
                            <p>Queen Of The Lizard People (14:16.411)
                            Look, buddy, you can run away from this one.</p>
                            <p>Bryan White (14:19.585)
                            Yeah, but this one but the the the short and you can find it on YouTube. It&#39;s called it&#39;s called Maniac 2 Mr. Robbie. And it was directed by buddy Joe Venazzo, who has done the similarly vile combat shock. And it&#39;s not a co it&#39;s not a continuation of this one because at the end, like Frank&#39;s dead at the end of this, you know, spoiler. But oh, I know, right? But it&#39;s it&#39;s just</p>
                            <p>Queen Of The Lizard People (14:43.932)
                            Or is he?</p>
                            <p>Queen Of The Lizard People (14:47.77)
                            Jesus fucking carry all over again. It&#39;s pieces. It&#39;s it&#39;s every one of them</p>
                            <p>Bryan White (14:50.365)
                            Yeah, yeah. Right, but it was supposed to be a remake of a movie called The Psychopath about a children&#39;s show host who kills abusive parents, and funding was almost complete in 1989, they were going to go into production, but production dropped when Spinell died and have you ever read about how he died it&#39;s fucking sad.</p>
                            <p>Queen Of The Lizard People (15:08.65)
                            I went tibbury about that and it was just like, well maybe it was a heart attack and I was like, maybe?</p>
                            <p>Bryan White (15:12.965)
                            No, no, no. He, okay, so about a few years after this, Spinel like really kind of burns out and he falls into it. He was like a party hearty type kind of guy. He liked to go out, he liked drinking. Yeah. So he had been out drinking, probably like doing coke and stuff like that. And he went home and he took a shower and he slipped in the shower and he like broke the door and he cut himself. It&#39;s kind of like how Stiff Baderstein actually.</p>
                            <p>Queen Of The Lizard People (15:23.433)
                            Yeah, I can tell</p>
                            <p>Queen Of The Lizard People (15:41.512)
                            Ugh, that&#39;s a sad one.</p>
                            <p>Bryan White (15:43.925)
                            And he he cut himself and he basically bled to death because he&#39;s a hemophiliac and rather than like go and get help. He just like went to bed and when people came looking for him, they basically they found him that way. So yeah, that is that is the reason why we don&#39;t we don&#39;t have anything any Joe Spinnell movies past 1989. Yep. Yep. This is this is a this is a real fun movie all around</p>
                            <p>Queen Of The Lizard People (16:07.795)
                            Mmph.</p>
                            <p>Bryan White (16:13.57)
                            starting from the top. So it&#39;s produced.</p>
                            <p>Queen Of The Lizard People (16:14.91)
                            say, I will say though, this, this, I generally did not care for this. There are some things about it that are interesting.</p>
                            <p>Bryan White (16:22.445)
                            I&#39;m gonna get to that, because there are lots of little compositions and scenes that while I don&#39;t think that anything really ties together well to make like a story that&#39;s really lasting, there is some really, really well-composed like filmmaking in this that really does merit mention. Yeah, so this was produced on a budget of $350,000 and it made over 10 million just at the box office.</p>
                            <p>Queen Of The Lizard People (16:30.294)
                            Yeah.</p>
                            <p>Queen Of The Lizard People (16:43.35)
                            Yeah, there are moments.</p>
                            <p>Bryan White (16:52.445)
                            since then, you know, it&#39;s obviously, you know, it&#39;s selling on video and DVD, Blu ray and all that. But most of the budget came from Monroe&#39;s husband, he bought, like I said, he bought the role for for Carolyn, basically. They, they basically released it regionally to New York first. And they had a really interesting marketing thing going on where inside the theaters that were showing it, they had these TV kiosks outside the theater that showed basically that trailer that we</p>
                            <p>Queen Of The Lizard People (17:17.549)
                            Oh yeah.</p>
                            <p>Bryan White (17:22.385)
                            has all of the like gory violence that you could that you basically you&#39;re showing up to see running on a loop and it became.</p>
                            <p>Queen Of The Lizard People (17:29.81)
                            And you won&#39;t believe this, Vincent Canby was deeply offended by that.</p>
                            <p>Bryan White (17:34.425)
                            Yeah, yeah. I mean, this I believe so Jean Siskel is like a noted anti horror movie, very, very anti slasher movie in particular. He&#39;s, I know he was anybody was a real crusader for it throughout the 80s. This was like his thing. And I believe that this was this movie and that sort of marketing technique was the reason that he he kind of launched into it. If this is never if some movie had never been made like he probably would never have have</p>
                            <p>Queen Of The Lizard People (17:44.073)
                            Fuck the both of them.</p>
                            <p>Bryan White (18:04.305)
                            gotten carried away. But he made so much noise about that particular quality of it where they had the TVs just showing where children were walking by that that other theaters just they scrapped the idea they wouldn&#39;t take them. This was released unrated rather than getting an X rating or cut it for an R rating and this would movie would absolutely have been butchered by the MPAA. I that&#39;s the thing is like if you were cut any of this movie for violence you&#39;d end up with like 20</p>
                            <p>Queen Of The Lizard People (18:27.57)
                            Yeah, what could you cut?</p>
                            <p>Queen Of The Lizard People (18:33.07)
                            with a bunch of bizarre monologues.</p>
                            <p>Bryan White (18:35.167)
                            Yeah, it&#39;s like 20 minutes of a movie about an artist with respiratory problems living in like a cramped Brooklyn apartment.</p>
                            <p>Queen Of The Lizard People (18:39.057)
                            lol</p>
                            <p>Queen Of The Lizard People (18:41.59)
                            Yeah, it should be set up front. This movie&#39;s about 60% gross noises and sweat.</p>
                            <p>Bryan White (18:46.205)
                            It&#39;s a lot of like, yeah, yeah, yeah. Like whenever he gets around, he starts to become like aroused. Most newspapers that would advertise unrated movies, that&#39;s the thing. Those were the reasons why people like would kind of go out of their way to avoid an X or an unraiding or like or going unrated because most newspapers in America would not advertise them. But the ones that would still wouldn&#39;t touch the this movie because of the art of the poster, which is</p>
                            <p>Bryan White (19:16.805)
                            pretty intense even for like a movie poster at the time. Like Friday the 13th was a contemporary, like a contemporary of this movie. And that one&#39;s got like a little blood on an illustrated knife. Like this one is a painting of a guy holding a knife at crotch level while holding in the other hand, a woman&#39;s scalp. And if you look closely, he&#39;s got a boner.</p>
                            <p>Queen Of The Lizard People (19:37.792)
                            Oh my god... For fuck&#39;s sake!</p>
                            <p>Bryan White (19:44.505)
                            So, in 1979, there was an yeah, yeah, yeah. So, in 1979, there was an ad in variety that reported that this movie would star Nickelodeon when she was still attached but also Jason Miller from The Exorcist and Grimbrow&#39;s favorite.</p>
                            <p>Queen Of The Lizard People (19:46.51)
                            You see what I mean about the horror bro thing? I get who this is for.</p>
                            <p>Queen Of The Lizard People (20:03.391)
                            Jason Miller, whose son I believe is that annoying kid from all the horror movie 80 movies. He&#39;s Homer, Homer from Near Dark.</p>
                            <p>Bryan White (20:09.125)
                            Yeah, from Near Dark. Yep, Jason Patrick is also one of his sons. Yeah, and also this movie was supposed to star Grimbrow&#39;s favorite, Susan Terrell. No idea who she was supposed to play in that movie.</p>
                            <p>Queen Of The Lizard People (20:14.45)
                            That&#39;s right.</p>
                            <p>Queen Of The Lizard People (20:20.394)
                            Ooh!</p>
                            <p>Queen Of The Lizard People (20:24.852)
                            Yeah.</p>
                            <p>Bryan White (20:25.285)
                            I can&#39;t even I can&#39;t even imagine. Because there&#39;s not. There&#39;s there&#39;s yeah, this is like all of the women in the movie get killed, so. Yeah, who knows? I mean, maybe there was a draft at a point that had more to it. I don&#39;t know. Cisco, Liliberte, as we said, hated this movie. Roger Ebert walked out after this.</p>
                            <p>Queen Of The Lizard People (20:27.65)
                            I mean, there aren&#39;t that many roles in this movie. There&#39;s like three people.</p>
                            <p>Queen Of The Lizard People (20:46.57)
                            Roger Ebert can eat a bag of dicks. This is the guy who wrote Beyond the Valley of the Dolls. Don&#39;t you tell me what&#39;s good and bad?</p>
                            <p>Bryan White (20:53.785)
                            I know I always come back to that. It&#39;s like you made, you wrote Beyond the Valley of the Dolls dude, like why are you so highfalutin? Like what&#39;s going on in your mind? Anyways, this is a man who got paid to write a review of this movie and he didn&#39;t make it past the Savini scene. It says, he says he walked out after it. There is, the score is awesome. I fucking love the music in this movie. It&#39;s,</p>
                            <p>Queen Of The Lizard People (21:02.67)
                            Yeah. Yeah, you&#39;re not better than us, okay?</p>
                            <p>Queen Of The Lizard People (21:18.27)
                            Yeah, the score is probably the best part about it because this I think this is one of Jay Chataway&#39;s first scores.</p>
                            <p>Bryan White (21:23.065)
                            It&#39;s.</p>
                            <p>Bryan White (21:25.485)
                            Yeah, and it&#39;s so good. I&#39;ve got notes about like, why it&#39;s so good, like a little further down, but like the general vibe of it is very buzzy and very nasty. There&#39;s all these like interstitial bits that are spooky with like a big like bass riff running through them.</p>
                            <p>Queen Of The Lizard People (21:46.71)
                            Yeah, there&#39;s like atonal drones throughout it that really like kind of kind of amp everything up.</p>
                            <p>Bryan White (21:53.705)
                            This is, yeah, like that&#39;s the thing. It was like this, this movie has a very weird sort of like a very intense vein of just despair running, running front to back. And, and the score really reflects that. Um, so, uh, yeah, so there&#39;s, that&#39;s just like, that&#39;s just a bit of, a bit of a, uh, a bit of flavor for, for what we&#39;re about to get into. So let&#39;s, let&#39;s, let&#39;s dive in.</p>
                            <p>Queen Of The Lizard People (22:21.65)
                            Wait a minute, is Frank Zito named after Joseph Zito, do you think? On my second watch, I thought, I wonder if that&#39;s the case.</p>
                            <p>Bryan White (22:24.986)
                            He is in fact named after Joseph Zito. He was...</p>
                            <p>Bryan White (22:30.405)
                            Yeah, he was he was a friend of Lustig&#39;s, I believe, and Tom Savini and a year like a probably the movie that Savini did special effects on after like right after this was on the prowler, which Zito did.</p>
                            <p>Queen Of The Lizard People (22:47.15)
                            which is an infinitely better film than this.</p>
                            <p>Bryan White (22:50.825)
                            Maybe I, I kind of like I feel like there&#39;s like tears of slasher movies and like you&#39;ve got your like, you know, your like s grade tier with all your you know, Freddy Krueger&#39;s and you know, Jason Voorhees is and then there&#39;s those ones that like, they were very clearly trying to set up like a character and that&#39;s just a movie underperformed and so they never went back to it like Cropsy and the and the minor from like my Bloody Valentine and like that one, that one.</p>
                            <p>Queen Of The Lizard People (23:17.673)
                            Easy now.</p>
                            <p>Bryan White (23:20.545)
                            I know, but I definitely feel like my bloody Valentine is in the lower tier of slasher movies, even though you...</p>
                            <p>Queen Of The Lizard People (23:26.45)
                            And I will, when we get to it, I will tell you all the reasons why you are wrong, and you should never say that again.</p>
                            <p>Bryan White (23:32.687)
                            Yeah. So, yeah, but also, yeah, and I&#39;ve known like, like that, but the success of the Prowler launched Zito to do eventually he made my favorite Friday the 13th, he made part four. Yeah, that&#39;s my absolute favorite. No, number one with a bullet.</p>
                            <p>Queen Of The Lizard People (23:45.252)
                            fourth one?</p>
                            <p>Queen Of The Lizard People (23:50.851)
                            Wow, even better than the third one.</p>
                            <p>Bryan White (23:51.585)
                            Yeah. I don&#39;t really like the third one. Yeah. Yeah. I if I had if I had to if I have to rank them, it&#39;s part four part two part one and part six and then maybe I think five is is marginally better than part eight.</p>
                            <p>Queen Of The Lizard People (23:54.99)
                            Well, you&#39;re wrong about that too.</p>
                            <p>Queen Of The Lizard People (24:06.39)
                            Why you just gonna skip over five like that, huh?</p>
                            <p>Queen Of The Lizard People (24:14.39)
                            Five is my second favorite. Yeah, I go three, five, two, one, four, eight, and seven.</p>
                            <p>Bryan White (24:16.005)
                            Damn!</p>
                            <p>Bryan White (24:23.765)
                            God damn. Man, we don&#39;t even touch nine and 10, even though I love Jason X, but. Ha ha ha ha.</p>
                            <p>Queen Of The Lizard People (24:30.897)
                            When was the last time you saw it? Because I watched it over the summer and it is a hard one to get through.</p>
                            <p>Bryan White (24:36.386)
                            It was a long time ago. Probably the last time I saw it was like around the time it came out. And I really enjoyed just how deep they lean into the more ridiculous aspects of it. Yeah, because I mean.</p>
                            <p>Queen Of The Lizard People (24:45.431)
                            Nah. Hey everybody, can you tell how much we liked Maniac?</p>
                            <p>Bryan White (24:52.408)
                            Oh, yeah. OK. No, I mean, yeah, fuck it. Let&#39;s get to it. So the movies we start out on on a windy winter shot of of probably like, I don&#39;t know, Rockaway Beach or.</p>
                            <p>Queen Of The Lizard People (24:53.271)
                            Ew, it&#39;s that good!</p>
                            <p>Queen Of The Lizard People (25:08.73)
                            Yeah, they just fucking slingshot you into this movie. There&#39;s no Amp up here. We just...</p>
                            <p>Bryan White (25:11.305)
                            Yeah, it&#39;s...</p>
                            <p>Bryan White (25:14.465)
                            Oh, yeah, it really does. It really does start and it&#39;s not even clear if this is like really happening. So, you know, you hear that you hear that the the J. Chataway kind of lurking sneaking part of the score and you know there&#39;s a dude like looking at watching a couple who&#39;s like sleeping on the beach. And Right.</p>
                            <p>Queen Of The Lizard People (25:34.45)
                            put like legit sleeping on the beach. They&#39;re not just like hanging out or resting. They&#39;re like homeless.</p>
                            <p>Bryan White (25:40.265)
                            This very could easily have been like, hey, like boobs everybody like they really couldn&#39;t like this. That&#39;s kind of what you&#39;re expecting, but like, no, they&#39;re just sleeping. Like they don&#39;t have a home. We&#39;re like right immediately. This is, this is, we&#39;re right in murder town. They, they do the classic like split up where she is like, oh, the fire&#39;s dying, go get fire, like firewood. And he&#39;s like, ah, babe, let me sleep. But you know, he goes and he does it while he&#39;s away.</p>
                            <p>Queen Of The Lizard People (25:44.433)
                            Yeah.</p>
                            <p>Bryan White (26:10.265)
                            scene goes up and sort of like just touches her and she thinks it&#39;s the boyfriend and then she gets slashed on the throat with a like a you know, a straight razor and the boyfriend comes back and he sees her and drops the firewood is like, Oh my god. And the killer comes up behind him and gets him with a grot like lifts him off the ground and he&#39;s like, you know, you basically want to like watch him dies his feet are kicking and there&#39;s you know, there&#39;s blood. This actually</p>
                            <p>Queen Of The Lizard People (26:24.764)
                            Hmm</p>
                            <p>Queen Of The Lizard People (26:36.674)
                            This is the Annie kill from Friday the 13th.</p>
                            <p>Bryan White (26:40.725)
                            Yeah, yeah, it&#39;s it&#39;s and it&#39;s a really it&#39;s a really good it&#39;s a really good like you can see Savini reuses a lot of his Friday. It basically is. It&#39;s some really good work to like this is some of the from like the best stuff he&#39;s done. Like 1980 was a real like turning point for him because like before that like I love I love Dawn of the Dead, but a lot of the effects are really janky.</p>
                            <p>Queen Of The Lizard People (26:47.91)
                            Yeah, this is Tom Savini&#39;s greatest hits this whole movie. You can see a lot of this shit in other stuff.</p>
                            <p>Queen Of The Lizard People (26:56.95)
                            Mm-hmm.</p>
                            <p>Queen Of The Lizard People (27:11.65)
                            It&#39;s because they spent all their money renting that mall. That sweet mall.</p>
                            <p>Bryan White (27:23.396)
                            the</p>
                            <p>Queen Of The Lizard People (27:27.31)
                            Yep. The home of a proper serial killer.</p>
                            <p>Queen Of The Lizard People (27:33.951)
                            This is like, if central casting did housing, it&#39;s that. I think it literally says kill on the wall. Like there&#39;s doll heads everywhere. It&#39;s just like someone asked a 12 year old to design an apartment for a murderer.</p>
                            <p>Bryan White (27:40.247)
                            You know what?</p>
                            <p>Bryan White (27:42.925)
                            Yeah, yeah. This is.</p>
                            <p>Bryan White (27:51.247)
                            This is this really reminds me of the the kind of firefly family&#39;s affect in the in the House of 1000 Corpses where, like, it&#39;s just all this crap that you would imagine to find in like a murderer&#39;s house.</p>
                            <p>Queen Of The Lizard People (27:56.331)
                            Yeah. Yeah.</p>
                            <p>Queen Of The Lizard People (28:05.23)
                            Yeah, like no one has ever even heard of the concept of subtlety.</p>
                            <p>Bryan White (28:09.366)
                            Yeah, yeah.</p>
                            <p>Queen Of The Lizard People (28:11.49)
                            I mean, there&#39;s no Nazi quilts here. This is all.</p>
                            <p>Bryan White (28:14.625)
                            I don&#39;t know. But um it&#39;s kind of obvious like one of the central features of the room is he he wakes first of all he wakes up and just like in Butcher Baker, there&#39;s fucking candles lit all over the place and he&#39;s been sleeping fire hazard like come on uh but like the main features</p>
                            <p>Queen Of The Lizard People (28:29.575)
                            Yep.</p>
                            <p>Queen Of The Lizard People (28:32.792)
                            Oh yeah.</p>
                            <p>Queen Of The Lizard People (28:36.35)
                            It&#39;s a good thing he doesn&#39;t have a Nazi quilt, that thing would go up in a second.</p>
                            <p>Bryan White (28:39.905)
                            Yeah, because you know he&#39;s not using like the fine, like the fine yarn. He&#39;s using the stuff that goes up.</p>
                            <p>Queen Of The Lizard People (28:44.836)
                            No. This is like 1980. This is 79 yarn. Yeah.</p>
                            <p>Bryan White (28:49.405)
                            Polyester was huge then. Yeah. So this is this and this is a direct riff on psycho on Hitchcock&#39;s psycho. He&#39;s got a he&#39;s got like a shrine to like a woman who&#39;s kind of wicked, like tough bitch look about her. Like every time you see her, like her hair is all tied up in like like a bandana. She&#39;s like smoking a cigarette in this picture.</p>
                            <p>Queen Of The Lizard People (29:15.77)
                            Yeah, like the implication here is like, you know, this is like his bitch mother. We&#39;re going, we&#39;re gonna go that trope. We&#39;re gonna lean into that one a little bit later. But also is like based on the picture is your mother also like a model for British Vogue?</p>
                            <p>Bryan White (29:28.605)
                            That or is she like a jammer for a roller derby team?</p>
                            <p>Queen Of The Lizard People (29:31.87)
                            Yeah, like where is this photograph from? And why is it taking two weeks before they shot this?</p>
                            <p>Bryan White (29:38.985)
                            the old that yeah yeah there&#39;s there&#39;s some old like some old tiny pictures in the apartment but that photo looks very like modern. And so yeah like as as we find out like Frank Scott mother issues and almost immediately after he walks he wakes up he he puts on all his his you know his winter gear because it&#39;s cold and then he goes out into the night.</p>
                            <p>Queen Of The Lizard People (29:42.75)
                            Thank you.</p>
                            <p>Queen Of The Lizard People (30:04.05)
                            and I&#39;ll see you in the next one. Bye.</p>
                            <p>Bryan White (30:06.227)
                            But, uh...</p>
                            <p>Bryan White (30:13.111)
                            I just lost you, but you&#39;re back.</p>
                            <p>Bryan White (30:16.625)
                            Yeah. So, yeah, almost immediately, Frank wakes up and puts on all of his winter stuff because it&#39;s cold out and he takes off into the night. Oh, but before that, he like takes off because he&#39;s in his pajamas. He takes off his shirt and we see like he&#39;s basically his chest covered scars and he&#39;s like this.</p>
                            <p>Bryan White (30:40.712)
                            I don&#39;t think so.</p>
                            <p>Bryan White (30:43.285)
                            Nope, I&#39;m still recording.</p>
                            <p>Bryan White (30:47.706)
                            Oh, it says that yours did.</p>
                            <p>Bryan White (30:51.685)
                            Let me stop this.</p>
                            <p>Bryan White (30:57.945)
                            just bring it back.</p>
                            <p>Bryan White (31:03.265)
                            All right, I&#39;m just gonna stop it and bring it back. All right, I&#39;m just gonna stop it and bring it back.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/the-sentinel">Next Episode</Link></li>
                        <li><Link to="/episodes/pieces">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)